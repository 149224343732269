import { HTTP, HTTPUPLOAD } from "@/service/axios";

const leadScoring = {
    namespaced: true,
    state: {
        queues: [],
        total: 0,
    },
    mutations: {
        SET_DATA(state, data) {
            state.queues = data.obj;
            state.total = data.total
        }
    },
    actions: {
        fetchQueueList({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post('/queue/import', payload)
                    .then(res => {
                        resolve(res.data);
                        commit("SET_DATA", res.data);
                        return;
                    }).catch(err => reject(err.responseJSON));
            });
        },
        destroyQueue({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.delete(`/queue/import-delete/${payload}`)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        },
        downloadFile({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/queue/import-download/${payload}`)
                    .then((res) => resolve(res.data))
                    .catch((err) => reject(err.responseJSON));
            });
        },
        async importLeadScoring({ commit }, payload) {
            const config = {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            };
            return new Promise((resolve, reject) => {
                HTTPUPLOAD.post(`lead/scoring/import`, payload, config)
                    .then(res => resolve(res.data))
                    .catch(err => reject(err.response));
            });
        },
        checkQueue({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.get(`/queue/check`)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        }
    },
    getters: {}
}

export default leadScoring;