import {
  HTTP
} from "@/service/axios";
export default {
  methods: {
    checkAuth() {
      this.$confirm('กรุณาเข้าสู่ระบบใหม่อีกครั้ง', {
        confirmButtonText: 'OK',
        showCancelButton: false,
        type: 'error'
      }).then(() => {
        setTimeout(() => {
          window.localStorage.eram.clear();
        }, 200);
        this.$store.commit("SIGNOUT");
        window.localStorage.removeItem("eram");
        delete HTTP.defaults.headers.common.Authorization;
        this.$router.push("/");
        setTimeout(() => {
          this.$router.go();
        }, 250);
      })
    },
    // backMenuAuth() {
    //   this.$confirm('คุณไม่มีสิทธิเข้าถึงหน้านี้', {
    //     confirmButtonText: 'OK',
    //     showCancelButton: false,
    //     type: 'error',
    //     willClose: () => {
    //       window.history.back();
    //     }
    //   }).then(() => {
    //     window.history.back();
    //   })
    // }
  },
}