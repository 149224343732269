import axios from "axios";

export const HTTP = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 5 * 1000 * 60,
});

export const HTTPUPLOAD = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  timeout: 60 * 1000 * 60,
});
export const TERAK = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_TERAK,
});