import Vue from "vue";
import Vuex from "vuex";
// @ts-ignore
import initState from "./initState";
import createPersistedState from "vuex-persistedstate";
import mutation from "./mutation";
import leadscoring from './modules/leadScoring';
import userModule from './modules/user';
import dashboardModule from './modules/dashboard';
import criterionModule from './modules/criterion';
import { HTTP } from "@/service/axios";


Vue.use(Vuex);
const plugins = [
  createPersistedState({
    key: "CFOE",
  }),
];
export default new Vuex.Store({
  state: initState,
  mutations: mutation,
  actions: {
    destroyLeadScoring({ commit }, payload) {
      return new Promise((resolve, reject) => {
        var user = localStorage.getItem('CFOE');
        user = JSON.parse(user);
        var config = {
          headers: {
            'Authorization': `Bearer ${user._token}`,
            'Content-Type': 'application/json'
          }
        }
        HTTP.post('/lead/scoring/delete', payload, config)
          .then(res => {
            resolve(res.data)
          })
          .catch(err => reject(err.response.data));
      });
    },
    getAllLeadScoring({ commit }) {
      return new Promise((resolve, reject) => {
        var user = localStorage.getItem('CFOE');
        user = JSON.parse(user);
        var config = {
          headers: {
            'Authorization': `Bearer ${user._token}`,
            'Content-Type': 'application/json'
          }
        }
        HTTP.get('/lead/scoring/all', config)
          .then(res => resolve(res.data))
          .catch(err => reject(err.response.data));
      });
    }
  },
  modules: {
    leadscoring: leadscoring,
    user: userModule,
    dashboard: dashboardModule,
    criterion: criterionModule,
  },
  plugins,
});
