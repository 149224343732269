<template>
  <div v-loading="fullLoading">
    <div id="nav-left2">
      <el-menu
        :default-active="$route.meta.head"
        class="el-menu-vertical-demo"
        @open="handleOpen"
        @close="handleClose"
        :collapse="isCollapse"
        :class="isCollapse == false ? 'open-collapse' : ''"
      >
        <a class="home-nav">
          <li>
            <img
              style="cursor: pointer"
              src="@/assets/iconMenu/home.svg"
              alt=""
              class="icon"
              :style="!isCollapse ? 'transform: rotate(180deg);' : ''"
              @click="clickCollapse"
            />

            <span slot="title">
              <div
                class="custom-home-icon"
                :class="!isCollapse ? 'show' : 'no-show'"
              >
                <span></span>
                <p class="mg-0 font-24 font-weight-500">CFOE</p>
                <i
                  class="fas fa-times"
                  style="cursor: pointer"
                  @click="clickCollapse"
                ></i>
              </div>
            </span>
          </li>
        </a>
        <el-submenu
          index="1"
          :class="checkPermission(22) === 'd-none' ? 'd-none' : ''"
        >
          <template slot="title">
            <img src="@/assets/iconMenu/1.svg" alt="" class="icon" />
            <span slot="title">ภาพรวม</span>
          </template>
          <el-menu-item
            index="1-1"
            :class="checkPermission(23) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/dashboard').catch(() => {})"
          >
            <a>ภาพรวมทั้งหมด</a>
          </el-menu-item>
          <el-menu-item
            index="1-2"
            :class="checkPermission(24) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/dashboard-credit').catch(() => {})"
            ><a>ภาพรวมสินเชื่อ</a>
          </el-menu-item>
          <el-menu-item
            index="1-3"
            :class="checkPermission(25) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/dashboard-insurance').catch(() => {})"
            ><a>ภาพรวมประกันการส่งออก</a></el-menu-item
          >
          <el-menu-item
            index="1-4"
            :class="checkPermission(26) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/dashboard-project').catch(() => {})"
            ><a>ภาพรวมโครงการ</a></el-menu-item
          >
        </el-submenu>
        <a :class="checkPermission(2)">
          <el-menu-item
            index="2"
            @click="$router.push('/criteria-credit').catch(() => {})"
          >
            <img src="@/assets/iconMenu/2.svg" alt="" class="icon" />
            <span slot="title">เกณฑ์พิจารณาจัดเกรดสินเชื่อ </span>
          </el-menu-item>
        </a>
        <el-submenu
          index="3"
          :class="
            checkPermission('checkManageAll') === 'd-none' ? 'd-none' : ''
          "
        >
          <template slot="title">
            <img src="@/assets/iconMenu/3.svg" alt="" class="icon" />
            <span slot="title">จัดการข้อมูลการนำเสนอ </span>
          </template>
          <el-menu-item
            index="3-1"
            @click="$router.push('/manage/credit').catch(() => {})"
            :class="checkPermission(16) === 'd-none' ? 'd-none' : ''"
            ><a>จัดการข้อมูลสินเชื่อ</a>
          </el-menu-item>
          <el-menu-item
            index="3-2"
            :class="checkPermission(17) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/manage/export-insurance').catch(() => {})"
          >
            <a>จัดการข้อมูลประกันการส่งออก</a>
          </el-menu-item>
          <el-menu-item
            index="3-3"
            @click="$router.push('/manage/project').catch(() => {})"
            :class="checkPermission(18) === 'd-none' ? 'd-none' : ''"
          >
            <a>จัดการข้อมูลโครงการ </a>
          </el-menu-item>
        </el-submenu>
        <!-- <a :class="checkPermission('admin')">
          <el-menu-item
            index="4"
            @click="$router.push('/admin').catch(() => {})"
          >
            <img src="@/assets/iconMenu/4.svg" alt="" class="icon" />
            <span slot="title">จัดการสิทธิ์ผู้ดูแลระบบ </span>
          </el-menu-item>
        </a> -->
        <el-submenu
          index="9"
          :class="checkPermission('superAdmin') === 'd-none' ? 'd-none' : ''"
        >
          <template slot="title">
            <img src="@/assets/iconMenu/4.svg" alt="" class="icon" />
            <span slot="title">จัดการสิทธิ์ผู้ใช้งาน</span>
          </template>
          <el-menu-item
            index="9-1"
            :class="checkPermission(3) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/manage-admin/add-role').catch(() => {})"
          >
            <!-- <a>สิทธิ์ผู้ดูแลระบบ</a> -->
            <a>สิทธิ์ผู้ใช้งาน</a>
          </el-menu-item>
          <el-menu-item
            index="9-2"
            :class="checkPermission(4) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/manage-admin/add-admin').catch(() => {})"
          >
            <!-- <a>ผู้ดูแลระบบ</a> -->
            <a>เพิ่มผู้ใช้งาน</a>
          </el-menu-item>

          <el-menu-item
            index="9-3"
            :class="checkPermission(5) === 'd-none' ? 'd-none' : ''"
            @click="$router.push('/manage-admin/manage-role').catch(() => {})"
          >
            <!-- <a>จัดการสิทธิ์ผู้ดูแลระบบ</a> -->
            <a>จัดการสิทธิ์</a>
          </el-menu-item>
        </el-submenu>
        <a :class="checkPermission(8)">
          <el-menu-item
            index="5"
            @click="$router.push('/user').catch(() => {})"
          >
            <img src="@/assets/iconMenu/5.svg" alt="" class="icon" />
            <span slot="title">จัดการข้อมูลผู้ประกอบการ </span>
          </el-menu-item>
        </a>

        <a :class="checkPermission(31)">
          <el-menu-item
            index="8"
            @click="$router.push('/lead-scoring').catch(() => {})"
          >
            <i class="fas fa-address-card icon font-16"></i>
            <span slot="title">Lead Scoring</span>
          </el-menu-item>
        </a>
        <a :class="checkPermission(28)">
          <el-menu-item
            index="6"
            @click="$router.push('/import/export').catch(() => {})"
          >
            <img src="@/assets/iconMenu/6.svg" alt="" class="icon" />
            <span slot="title">Import/Export Data </span>
          </el-menu-item>
        </a>
        <a :class="checkPermission(32)">
          <el-menu-item
            index="9"
            @click="$router.push('/email-notification').catch(() => {})"
          >
            <i class="fas fa-envelope icon font-16"></i>
            <span slot="title">Email Notification</span>
          </el-menu-item>
        </a>
        <a :class="checkPermission(29)">
          <el-menu-item
            index="7"
            @click="$router.push('/transaction-log').catch(() => {})"
          >
            <img src="@/assets/iconMenu/7.svg" alt="" class="icon" />
            <span slot="title">Transaction Log </span>
          </el-menu-item>
        </a>
      </el-menu>
    </div>
    <div id="header-template" :class="{ isOpen: !isCollapse }">
      <div class="inside">
        <img src="@/assets/logo.png" alt="" class="logo" />
        <div class="is-flex ai-center">
          <el-tooltip
            class="item"
            effect="dark"
            content="What's new"
            placement="left"
          >
            <a href="/changelog" target="_blank" class="pd-t-7">
              <i class="el-icon-warning-outline rotate-180 icon-info"></i>
            </a>
          </el-tooltip>
          <!-- <i class="fas fa-bell bell-noti"></i> -->
          <el-dropdown trigger="click">
            <span class="el-dropdown-link">
              <div class="profile-header">
                <img src="@/assets/user.png" class="user-profile" alt="" />
                <div class="name-position">
                  <p>{{ user.empFname + " " + user.empLname }}</p>
                  <p class="color-9c">
                    {{ user.role.roleName }}
                  </p>
                </div>
                <i class="el-icon-arrow-down el-icon--right pd-x-5"></i>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <div @click="signOut">ออกจากระบบ</div>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div
      id="content-detail"
      style="min-height: calc(100vh - 68px)"
      :class="{
        'no-padding':
          $route.meta.groupHead != '1' &&
          $route.meta.groupHead != '3' &&
          $route.meta.subHead != '5-3' &&
          $route.name != 'SuperAdminAddAdminAdd',
        'not-allowed': $route.name == 'NotAllowed',
        isOpen: !isCollapse,
      }"
    >
      <slot />
    </div>
  </div>
</template>
<style>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 210px;
}
</style>
<script>
import { HTTP } from "@/service/axios";
export default {
  computed: {
    isCollapse() {
      return this.$store.state.toggleMenu;
    },
    user() {
      return this.$store.state.user;
    },
    // getPermission() {
    //   return this.$store.state.user.permission;
    // },
    _token() {
      return this.$store.state._token;
    },
  },
  data() {
    return {
      setIntervalData: "",
      loadingData: false,
      fullLoading: false,
    };
  },
  created() {
    this.getProfile();
    this.setIntervalData = setInterval(() => {
      this.getProfile();
    }, 1000 * 60 * 60);
  },
  methods: {
    getProfile() {
      HTTP.defaults.headers.common.Authorization = `Bearer ${this._token}`;
      HTTP.get(`profile`)
        .then((res) => {
          if (res.data.success) {
            if (
              res.data.obj.status == 1 &&
              res.data.obj.empStatus == "Active"
            ) {
              this.$store.commit("SET_USER", res.data.obj);
              if (
                typeof this.user.permissionCustom != "undefined" &&
                this.user.permissionCustom.length > 0
              ) {
                this.$store.commit(
                  "SET_PERMISSION_NOW",
                  this.user.permissionCustom
                );
              } else {
                this.$store.commit(
                  "SET_PERMISSION_NOW",
                  this.user.role.permission
                );
              }
              this.loadingData = true;
              return;
            } else {
              this.signOut();
            }
          }
        })
        .catch((e) => {
          if (typeof e.response != "undefined" && e.response.status != 502) {
            if (e.response.data.status == 401) {
              this.checkAuth();
            } else if (e.response.data.status == 502) {
              console.log("Bad GetWay");
            } else {
              this.alertCatchError(e);
            }
          }
        });
    },
    checkPermission(name) {
      if (this.user != "") {
        let result = "";
        let arrPermission = [];
        if (
          typeof this.user.permissionCustom != "undefined" &&
          this.user.permissionCustom.length > 0
        ) {
          arrPermission = this.user.permissionCustom;
        } else {
          arrPermission = this.user.role.permission;
        }
        if (name == "checkManageAll") {
          let ifPermissionCredit = arrPermission.some(
            (a) => a.pemNum == 16 && a.status != 3
          );
          let ifPermissionInsurance = arrPermission.some(
            (a) => a.pemNum == 17 && a.status != 3
          );
          let ifPermissionProject = arrPermission.some(
            (a) => a.pemNum == 18 && a.status != 3
          );
          if (
            ifPermissionCredit === false &&
            ifPermissionInsurance === false &&
            ifPermissionProject === false
          )
            result = "d-none";
        } else if (name == "superAdmin") {
          let ifPermissionRole = arrPermission.some(
            (a) => a.pemNum == 3 && a.status != 3
          );
          let ifPermissionAdmin = arrPermission.some(
            (a) => a.pemNum == 4 && a.status != 3
          );
          let ifPermissionManage = arrPermission.some(
            (a) => a.pemNum == 5 && a.status != 3
          );
          if (
            ifPermissionRole == true ||
            ifPermissionAdmin == true ||
            ifPermissionManage == true
          ) {
            result = "";
          } else {
            result = "d-none";
          }
        } else if (name == 8) {
          let ifPermissionInfo = arrPermission.some(
            (a) => a.pemNum == 8 && a.status != 3
          );
          if (ifPermissionInfo === true) {
            let ifPermissionInfo = arrPermission.some(
              (a) => a.pemNum == 9 && a.status != 3
            );
            let ifPermissionCompany = arrPermission.some(
              (a) => a.pemNum == 10 && a.status != 3
            );
            let ifPermissionCompany1 = arrPermission.some(
              (a) => a.pemNum == 30 && a.status != 3
            );
            let ifPermissionDownload = arrPermission.some(
              (a) => a.pemNum == 11 && a.status != 3
            );
            let ifPermissionExport = arrPermission.some(
              (a) => a.pemNum == 12 && a.status != 3
            );
            if (
              ifPermissionInfo === false &&
              ifPermissionCompany === false &&
              ifPermissionCompany1 === false &&
              ifPermissionDownload === false &&
              ifPermissionExport === false
            )
              result = "d-none";
          } else {
            result = "d-none";
          }
        } else {
          let ifPermission = arrPermission.some(
            (a) => a.pemNum == name && a.status != 3
          );
          if (ifPermission === false) result = "d-none";
        }
        return result;
      }
    },
    clickCollapse() {
      this.$store.commit("SET_OPEN_MENU", !this.isCollapse);
      this.$emit("reRenderChart");
    },
    handleOpen(key, keyPath) {
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      // console.log(key, keyPath);
    },
    async signOut() {
      clearInterval(this.setIntervalData);
      this.fullLoading = true;
      this.loadingData = false;
      setTimeout(() => {
        window.localStorage.eram.clear();
      }, 200);
      // this.$store.commit("SIGNOUT");
      window.localStorage.removeItem("CFOE");
      delete HTTP.defaults.headers.common.Authorization;
      //this.$router.push("/");
      setTimeout(() => {
        window.location.href = "/";
      }, 250);
      return true;
    },
  },
};
</script>
