import { HTTP, HTTPUPLOAD } from "@/service/axios";

export default {
    namespaced: true,
    state: {},
    mutations: {

    },
    actions: {
        fetchChannelInterestProductDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(`user/ch/details`, payload)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        },
        fetchChannelInterestProduct({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(`user/ch/interest/details`, payload)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        },
        fetchInterestCreditDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(`user/details/credit`, payload)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        },
        fetchInterestInsuranceDetails({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(`user/details/insurance`, payload)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        },
        fetchInterestInsurance({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(`user/interest/insurance`, payload)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        },
        fetchInterestCredit({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(`user/interest/credit`, payload)
                    .then(res => resolve(res.data))
                    .catch(error => reject(error.responseJSON));
            });
        }
    },
    getters: {}
}