import industryType from "./constant/industryType";
import userOption from "./constant/UserOption";
import userProject from "./constant/Project";
const initState = () => ({
  industryType: industryType,
  memberOfOrganize: [
    {
      label: "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย (EXIM Bank)",
      value: 13,
      disable: false,
      shortName: "EXIM Bank",
    },{
      label: "สภาผู้ส่งสินค้าทางเรือแห่งประเทศไทย (TNSC)",
      value: 1,
      disable: false,
      shortName: "TNSC",
    },
    {
      label: "สภาอุตสาหกรรมแห่งประเทศไทย (F.T.I)",
      value: 2,
      disable: false,
      shortName: "F.T.I.",
    },
    {
      label: "สภาหอการค้าแห่งประเทศไทย (TCC)",
      value: 3,
      shortName: "TCC",
    },
    {
      label: "สำนักงานส่งเสริมวิสาหกิจขนาดกลางและขนาดย่อม (สสว.)",
      value: 4,
      disable: false,
      shortName: "สสว.",
    },
    {
      label: "กรมส่งเสริมการค้าระหว่างประเทศ (DITP)",
      value: 5,
      disable: false,
      shortName: "DITP",
    },
    {
      label: "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย (SME D Bank)",
      value: 8,
      disable: false,
      shortName: "SME D Bank",
    },
    {
      label: "ตลาดหลักทรัพย์แห่งประเทศไทย (SET)",
      value: 9,
      disable: false,
      shortName: "SET",
    },
    {
      label: "หน่วยงานอื่นๆ",
      value: 6,
      disable: false
    },
    {
      label: "ไม่ได้เป็นสมาชิกหน่วยงานใด",
      value: 7,
      disable: false
    },
  ],
  toggleMenu: false,
  userOption: userOption,
  userProject: userProject,
  _token: null,
  user: [],
  pageActive: null,
  permissionAll:[],
  permissionNow:[],
  nameDashboardCredit:'',
  tableTypeCredit:1,
  tableTypeInsurance:1,
  tableTypeProject:1,
  showHideCriteria:false,
  showHideCredit:false,
  showHideInsurance:false,
  showHideProject:false,
});
export default initState;