import { HTTP, HTTPUPLOAD } from "@/service/axios";

export default {
    namespaced: true,
    state: {
    },
    mutations: {
    },
    actions: {
        GETBYID({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return HTTP.get(`criterion/${payload}`)
                    .then(res => {
                        resolve(res.data);
                        return;
                    }).catch(err => {
                        let response = err.response.data;
                        if (!response) response = { success: false, status: err.response.status, data: err.response.statusText }
                        reject(response);
                        return;
                    });
            })
        },
        UPDATE({ commit }, payload) {
            return new Promise((resolve, reject) => {
                return HTTP.put(`criterion/${payload._id}`, {
                    criterionName: payload.criterionName
                })
                    .then((res) => resolve(res.data))
                    .catch(err => {
                        let response = err.response.data;
                        if (!response) {
                            response = { success: false, data: err.response.statusText, status: err.response.status };
                        }
                        reject(response)
                        return;
                    });
            });
        }
    },
    getters: {

    }
}