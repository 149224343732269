import initState from "./initState";
import axios from "axios";
import {
  HTTP
} from "@/service/axios";

export default {
  SIGNOUT: (state) => {
    state.user = null;
    window.localStorage.removeItem("CFOE");
    delete HTTP.defaults.headers.common.Authorization;

    const initial = initState();
    Object.keys(initial).forEach((key) => {
      state[key] = initial[key];
    });
  },
  SET_OPEN_MENU: (state, payload) => {
    state.toggleMenu = payload;
  },
  SET_TOKEN: (state, payload) => {
    state._token = payload;
    HTTP.defaults.headers.common["Authorization"] = `Bearer ${payload}`;
  },
  SET_USER: (state, payload) => {
    state.user = payload;
  },
  SET_PAGE_ACTIVE: (state, payload) => {
    state.pageActive = payload;
  },
  SET_PERMISSION_ALL: (state, payload) => {
    state.permissionAll = payload;
  },
  SET_PERMISSION_NOW: (state, payload) => {
    state.permissionNow = payload;
  },
  SET_NAME_DASHBOARD_CREDIT: (state, payload) => {
    state.nameDashboardCredit = payload;
  },
  SET_SHOW_CREDIT: (state, payload) => {
    state.tableTypeCredit = payload;
  },
  SET_SHOW_INSURANCE: (state, payload) => {
    state.tableTypeInsurance = payload;
  },
  SET_SHOW_PROJECT: (state, payload) => {
    state.tableTypeProject = payload;
  },
  SET_SHOW_CRITERIA: (state, payload) => {
    state.showHideCriteria = payload;
  },
  SET_SHOW_CREDIT_HIDE: (state, payload) => {
    state.showHideCredit = payload;
  },
  SET_SHOW_INSURANCE_HIDE: (state, payload) => {
    state.showHideInsurance = payload;
  },
  SET_SHOW_PROJECT_HIDE: (state, payload) => {
    state.showHideProject = payload;
  }
};