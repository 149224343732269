let dt = new Date();
let nowYear = dt.getFullYear() + 543
export default {
  yearOfInterest: [{
      value: '',
      label: "ทั้งหมด"
    },
    {
      value: nowYear,
      label: nowYear
    },
    {
      value: nowYear - 1,
      label: nowYear - 1
    },
    {
      value: nowYear - 2,
      label: nowYear - 2
    },
    {
      value: nowYear - 3,
      label: nowYear - 3
    },
    {
      value: nowYear - 4,
      label: nowYear - 4
    },
  ],
  gradeCriteria: [{
      value: "all",
      label: "ทั้งหมด",
    },
    {
      value: 1,
      label: "ผู้ที่ผ่านเกณฑ์พิจารณาเกรด",
    },
    {
      value: 2,
      label: "ผู้ที่เกือบผ่านเกณฑ์พิจารณาเกรด",
    },
    {
      value: 3,
      label: "ผู้ที่ไม่ผ่านเกณฑ์พิจารณาเกรด",
    },
  ],
  businessSize: [{
      value: "",
      label: "ทั้งหมด",
    },
    {
      value: "S",
      label: "ธุรกิจขนาดเล็ก (S)",
    },
    {
      value: "M",
      label: "ธุรกิจขนาดกลาง (M)",
    },
    {
      value: "L",
      label: "ธุรกิจขนาดใหญ่ (L)",
    },
  ],
  statusCRM: [{
      value: 2,
      label: "New",
    },
    {
      value: 0,
      label: "Pending",
    },
    {
      value: 1,
      label: "Active",
    },
    {
      value: 3,
      label: "Submitted",
    },
    {
      value: 4,
      label: "Close",
    },
  ],
  interestedServices: [{
      value: 'credit',
      label: "สินเชื่อ",
    },
    {
      value: 'insurance',
      label: "ประกันการส่งออก",
    },
    {
      value: 'project',
      label: "โครงการอบรม",
    },
  ],
  loanStatus: [{
      value: 2,
      label: "ไม่อนุมัติ",
    }, {
      value: 1,
      label: "อนุมัติ",
    },
    {
      value: 3,
      label: "อนุมัติแบบมีเงื่อนไข",
    },

  ],
  title: [{
      value: 1,
      label: "นาย",
    },
    {
      value: 2,
      label: "นาง",
    },
    {
      value: 3,
      label: "นางสาว",
    },
    {
      value: 4,
      label: "ดร.",
    },
  ],
  education: [{
    label: "มัธยมศึกษา หรือ ต่ำกว่า",
    value: 1
  }, {
    label: "ปริญญาตรี",
    value: 2
  }, {
    label: "ปริญญาโท",
    value: 3
  }, {
    label: "ปริญญาเอก",
    value: 4
  }],
  businessType: [{
      label: "ธุรกิจบริการ",
      value: 1,
      shortName: "บริการ",
    },
    {
      label: "ธุรกิจอุตสาหกรรม หรือ ธุรกิจประเภทผลิต",
      value: 2,
      shortName: "ผลิต",
    },
    {
      label: "ธุรกิจพาณิชยกรรม หรือ ธุรกิจแบบซื้อมาขายไป",
      value: 3,
      shortName: "ซื้อมาขายไป",
    },
  ],
  employment: [{
      label: "1 - 5 คน",
      value: 1,
    },
    {
      label: "6 - 30 คน",
      value: 2,
    },
    {
      label: "31 - 50 คน",
      value: 3,
    },
    {
      label: "51 - 100 คน",
      value: 4,
    },
    {
      label: "101 - 200 คน",
      value: 5,
    },
    {
      label: "200 คน ขึ้นไป",
      value: 6,
    },
  ],
  operatorType: [{
      label: "ผู้ประกอบการค้าขายภายในประเทศ",
      value: 1,
    },
    {
      label: "ผู้ส่งออก",
      value: 3,
    },
  ],
  exportPlan: [{
      label: "มีแผน",
      value: 1,
    },
    {
      label: "ไม่มีแผน",
      value: 2,
    },
  ],
  exportExperience: [{
      label: "น้อยกว่า 1 ปี",
      value: 1,
    },
    {
      label: "1-2 ปี",
      value: 2,
    },
    {
      label: "3-5 ปี",
      value: 3,
    },
    {
      label: "เกิน 5 ปี",
      value: 4,
    },
  ],
  exportRatio: [{
      label: "1%-20% ของยอดขายทั้งหมด",
      value: 2,
    },
    {
      label: "21%-40% ของยอดขายทั้งหมด",
      value: 3,
    },
    {
      label: "เกิน 40% ของยอดขายทั้งหมด",
      value: 4,
    },
  ],
  orderConsistency: [{
      label: "เป็นครั้งคราว เป็นระยะเวลา 1-2 ปี",
      value: 2,
    },
    {
      label: "สม่ำเสมอ เป็นระยะเวลา 1-2 ปี",
      value: 1,
    },
    {
      label: "เป็นครั้งคราว เป็นระยะเวลามากกว่า 2 ปี",
      value: 4,
    },
    {
      label: "สม่ำเสมอ เป็นระยะเวลามากกว่า 2 ปี",
      value: 3,
    },
  ],
  exportFrequency: [{
      label: "มากกว่า 1 ครั้งต่อเดือน",
      value: 1,
    },
    {
      label: "มากกว่า 1 ครั้งต่อไตรมาส",
      value: 2,
    },
    {
      label: "มากกว่า 1 ครั้งต่อ 6 เดือน",
      value: 3,
    },
    {
      label: "มากกว่า 1 ครั้งต่อปี",
      value: 4,
    },
  ],
  paymentTerm: [{
      value: 0,
      label: "ไม่ใช้เงื่อนไขเทอมการชำระเงิน",
    },
    {
      value: 90,
      label: "ไม่เกิน 90 วัน",
    },
    {
      value: 120,
      label: "ไม่เกิน 120 วัน",
    },
    {
      value: 180,
      label: "ไม่เกิน 180 วัน",
    },
  ],
  exportCountry: [{
      label: "1-2 ประเทศ",
      value: 1,
    },
    {
      label: "3-5 ประเทศ",
      value: 2,
    },
    {
      label: "มากกว่า 5 ประเทศ",
      value: 3,
    },
  ],
  expectedPercentage: [{
      label: "ร้อยละ 1-5",
      value: 1,
    },
    {
      label: "ร้อยละ 6-10",
      value: 2,
    },
    {
      label: "ร้อยละ 11-15",
      value: 3,
    },
    {
      label: "ร้อยละ 16-20",
      value: 4,
    },
    {
      label: "อื่นๆ (โปรดระบุ)",
      value: 5,
    },
  ],
}