// @ts-nocheck
import Vue from "vue";
import VueRouter from "vue-router";
import store from '../store'
import NProgress from "nprogress";
Vue.use(VueRouter);

const ifPermissionAdmin = (to, from, next) => {
  if (typeof store.state._token === "undefined" ||
    store.state._token === null ||
    store.state._token === "") {
    next("/login");
  } else {
    let pageNow = to.meta.permission
    let arrPermission = [];
    if (store.state.user.permissionCustom.length > 0) {
      arrPermission = store.state.user.permissionCustom
    } else {
      arrPermission = store.state.user.role.permission
    }
    let ifPermission = arrPermission.find(
      (a) => a.pemNum == pageNow
    );

    if (ifPermission === undefined) {
      return next('not-allowed'); // กรณีที่ไม่มีข้อมูล Permission เลย
    }

    if (ifPermission.status != 3) {
      if (to.name == 'SuperAdminAddAdminAdd' || to.name == 'ManageCreditAdd' || to.name == 'ManageCreditEdit' ||
        to.name == 'ExportInsuranceAdd' || to.name == 'ExportInsuranceEdit' || to.name == 'ProjectAdd' || to.name == 'ProjectEdit') {
        if (ifPermission.status != 2) {
          next("/not-allowed");
        } else {
          next()
        }
      } else {
        next()
      }
      // if (store.state.user.role.roleLevel == 90 && to.name == 'Admin') {
      //   next("/manage-admin/manage-role");
      // } else if (store.state.user.role.roleLevel <= 80 && to.name == 'SuperAdminManageRole') {
      //   next("/admin");
      // } else {
      // }
    } else if (ifPermission.status === null || typeof ifPermission.status === "undefined" || ifPermission.status === '') {
      next("/not-allowed");
    } else {
      next("/not-allowed");
    }
  }
  next();
  return;

};
const ifPageActive = (to, from, next) => {
  const pageActive = store.state.pageActive
  if (store.state._token != "" && pageActive == null) {
    next();
  } else if (typeof store.state._token === "undefined" ||
    store.state._token === null ||
    store.state._token === "") {
    next();
  } else {
    if (store.state._token === null ||
      store.state._token === "") {
      next(`/login`);
    } else {
      next(`/${pageActive}`);
    }
  }
}

// const ifPermissionLeadScoring = (to, from, next) => {

//   if (store.state.user.role.roleLevel == 90 || store.state.user.role.roleLevel == 80 || store.state.user.role.roleLevel == 0) {
//     next()
//   } else {
//     next("/not-allowed");
//   }
// }

const routes = [{
  path: "*",
  component: () => import("@/views/ErrorScreen")
},
{
  path: "/",
  name: "Home",
  redirect: "/login",
},
{
  path: "/login",
  name: "Login",
  component: () => import("@/views/Login"),
  beforeEnter: ifPageActive,
},
{
  path: "/login/devv",
  name: "LoginDevv",
  component: () => import("@/views/LoginDev"),
  beforeEnter: ifPageActive,
},
{
  path: "/changelog",
  name: "ChangeLog",
  component: () => import("@/views/ChangeLog"),
},
{
  path: "/dashboard",
  name: "Dashboard",
  meta: {
    groupHead: '1',
    head: "1-1",
    subHead: "1-1",
    permission: 23,
  },
  component: () => import("@/views/Dashboard"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/dashboard-credit",
  name: "DashboardCredit",
  meta: {
    groupHead: '1',
    head: "1-2",
    subHead: "1-2",
    permission: 24,
  },
  component: () => import("@/views/DashboardCredit"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/dashboard-credit/:id",
  name: "DashboardCreditId",
  meta: {
    groupHead: '1',
    head: "1-2",
    subHead: "1-2",
    permission: 24,
  },
  component: () => import("@/views/DashboardCreditDetail"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/dashboard-insurance",
  name: "DashboardInsurance",
  meta: {
    groupHead: '1',
    head: "1-3",
    subHead: "1-3",
    permission: 25,
  },
  component: () => import("@/views/dashboardInsurance"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/dashboard-project",
  name: "DashboardProject",
  meta: {
    groupHead: '1',
    head: "1-4",
    subHead: "1-4",
    permission: 26,
  },
  component: () => import("@/views/dashboardProject"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/criteria-credit",
  name: "CriteriaCredit",
  meta: {
    head: "2",
    permission: 2,
  },
  component: () => import("@/views/CriteriaCredit"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/criteria-credit/detail/:id",
  name: "CriteriaCreditDetail",
  meta: {
    head: "2",
    permission: 2,
  },
  component: () => import("@/views/CriteriaCreditDetail"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/credit",
  name: "ManageCredit",
  meta: {
    groupHead: '3',
    head: "3-1",
    subHead: "3-1",
    permission: 16,
    permissionManage: 13,
  },
  component: () => import("@/views/ManageCredit"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/credit/add",
  name: "ManageCreditAdd",
  meta: {
    groupHead: '3',
    head: "3-1",
    subHead: "3-1",
    permission: 13,
  },
  component: () => import("@/views/ManageCreditAdd"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/credit/edit/:id",
  name: "ManageCreditEdit",
  meta: {
    groupHead: '3',
    head: "3-1",
    subHead: "3-1",
    permission: 13,
  },
  component: () => import("@/views/ManageCreditEdit"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/credit/detail/:id",
  name: "CreditDetail",
  meta: {
    groupHead: '3',
    head: "3-1",
    subHead: "3-1",
    permission: 19,
  },
  component: () => import("@/views/CreditDetail"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/export-insurance",
  name: "ExportInsurance",
  meta: {
    groupHead: '3',
    head: "3-2",
    subHead: "3-2",
    permission: 17,
    permissionManage: 14,
  },
  component: () => import("@/views/ExportInsurance"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/export-insurance/add",
  name: "ExportInsuranceAdd",
  meta: {
    groupHead: '3',
    head: "3-2",
    subHead: "3-2",
    permission: 14,
  },
  component: () => import("@/views/ExportInsuranceAdd"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/export-insurance/edit/:id",
  name: "ExportInsuranceEdit",
  meta: {
    groupHead: '3',
    head: "3-2",
    subHead: "3-2",
    permission: 14,
  },
  component: () => import("@/views/ExportInsuranceEdit"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/export-insurance/detail/:id",
  name: "ExportInsuranceDetail",
  meta: {
    groupHead: '3',
    head: "3-2",
    subHead: "3-2",
    permission: 20,
  },
  component: () => import("@/views/ExportInsuranceDetail"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/project",
  name: "Project",
  meta: {
    groupHead: '3',
    head: "3-3",
    subHead: "3-3",
    permission: 18,
    permissionManage: 15,
  },
  component: () => import("@/views/Project"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/project/detail/:id",
  name: "ProjectDetail",
  meta: {
    groupHead: '3',
    head: "3-3",
    subHead: "3-3",
    permission: 21,
  },
  component: () => import("@/views/ProjectDetail"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/project/add",
  name: "ProjectAdd",
  meta: {
    groupHead: '3',
    head: "3-3",
    subHead: "3-3",
    permission: 15,
  },
  component: () => import("@/views/ProjectAdd"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage/project/edit/:id",
  name: "ProjectEdit",
  meta: {
    groupHead: '3',
    head: "3-3",
    subHead: "3-3",
    permission: 15,
  },
  component: () => import("@/views/ProjectEdit"),
  beforeEnter: ifPermissionAdmin,
},

// {
//   path: "/admin",
//   name: "Admin",
//   meta: {
//     groupHead: '9',
//     head: "9-3",
//     subHead: "9-3",
//     permission: 5,
//     // head: "4",
//     // permission: 5,
//   },
//   component: () => import("@/views/Admin"),
//   beforeEnter: ifPermissionAdmin,
// },
{
  path: "/manage-admin/add-role",
  name: "SuperAdminAddRole",
  meta: {
    groupHead: '9',
    head: "9-1",
    subHead: "9-1",
    permission: 3,
  },
  component: () => import("@/views/SuperAdminAddRole"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage-admin/add-admin",
  name: "SuperAdminAddAdmin",
  meta: {
    groupHead: '9',
    head: "9-2",
    subHead: "9-2",
    permission: 4,
  },
  component: () => import("@/views/SuperAdminAddAdmin"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage-admin/add-admin/add",
  name: "SuperAdminAddAdminAdd",
  meta: {
    groupHead: '9',
    head: "9-2",
    subHead: "9-2",
    permission: 4,
  },
  component: () => import("@/views/SuperAdminAddAdminAdd"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/manage-admin/manage-role",
  name: "SuperAdminManageRole",
  meta: {
    groupHead: '9',
    head: "9-3",
    subHead: "9-3",
    permission: 5,
  },
  component: () => import("@/views/SuperAdminManageRole"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user",
  name: "User",
  meta: {
    head: "5",
    permission: 8,
  },
  component: () => import("@/views/User"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user/detail/:id",
  name: "UserDetail",
  meta: {
    head: "5",
    permission: 8,
  },
  component: () => import("@/views/UserDetail"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user/personal/:id/:criterionId",
  name: "UserPersonal",
  meta: {
    head: "5",
    permission: 9,
  },
  component: () => import("@/views/UserPersonal"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user/company/:id/:criterionId",
  name: "UserCompany",
  meta: {
    head: "5",
    permission: 10,
  },
  component: () => import("@/views/UserCompany"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user/credit/:id/:criterionId/:branch",
  name: "UserCredit",
  meta: {
    head: "5",
    subHead: "5-3",
    permission: 30, // ออ๊ฟแก้ไข
  },
  component: () => import("@/views/UserCredit"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user/insurance/:id/:criterionId/:branch",
  name: "UserInsurance",
  meta: {
    head: "5",
    subHead: "5-3",
    permission: 30, // 
  },
  component: () => import("@/views/UserInsurance"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/user/project/:id/:criterionId",
  name: "UserProject",
  meta: {
    head: "5",
    subHead: "5-3",
    permission: 30,
  },
  component: () => import("@/views/UserProject"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/import/export",
  name: "ImportExprotCSV",
  meta: {
    head: "6",
    permission: 28,
  },
  component: () => import("@/views/ImportExport"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/transaction-log",
  name: "TransactionLog",
  meta: {
    head: "7",
    permission: 29
  },
  component: () => import("@/views/TransactionLog"),
  beforeEnter: ifPermissionAdmin,
},
{
  path: "/not-allowed",
  name: "NotAllowed",
  component: () => import("@/views/NotAllowed"),
},
{
  path: "/lead-scoring",
  name: "LeadScoring",
  meta: {
    head: "8",
    permission: 31
  },
  beforeEnter: ifPermissionAdmin,
  component: () => import("@/views/LeadScoring"),
},
{
  path: "/lead-scoring/queue",
  name: "LeadScoring.queue",
  meta: {
    head: "8",
    permission: 31
  },
  beforeEnter: ifPermissionAdmin,
  component: () => import("@/views/QueueImport"),
},
{
  path: "/email-notification",
  name: "EmailNotification",
  meta: {
    head: "9",
    permission: 32
  },
  component: () => import("@/views/EmailNotification"),
  beforeEnter: ifPermissionAdmin,
},
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  // start progress bar
  NProgress.start();
  next();
});


router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});

export default router;