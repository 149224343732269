      import {
        mapState
      } from "vuex";

      export default {

        computed: {
          ...mapState({
            permissionNow: (state) => state.permissionNow,
          }),
        },
        methods: {
          totalPercent(value, total) {
            let sum = (value * 100) / total
            return sum.toFixed(2)
          },
          checkPermission(num) {
            let ifPermission = null;
            ifPermission = this.permissionNow.some(
              (e) => e.pemNum == num && e.status != 3
            );
            if (ifPermission === false) {
              return false;
            } else {
              return true;
            }
          },
          checkOpenCollapse(name, arr) {
            let findData = null;
            findData = arr.find(
              (e) => e == name
            );
            if (typeof findData != 'undefined') {
              return true;
            } else {
              return false;
            }
          }
        },
      }