import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
import '@/styles/navbarLeft.scss'
import '@/styles/index.scss'

import locale from 'element-ui/lib/locale/lang/en'
import moment from 'moment';
import momentTH from "moment/src/locale/th";
import Auth from "@/service/Auth";
import CalPercent from "@/service/CalPercent";

import "@/service/alert";
import numeral from "numeral";
import VueNumeric from "vue-numeric";
import "animate.css"
import "nprogress/nprogress.css";

import VueQuillEditor from 'vue-quill-editor'
import Quill from "quill";

import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.bubble.css"; // for bubble theme
import {
  ImageDrop
} from "quill-image-drop-module";
import ImageResize from "quill-image-resize-module--fix-imports-error";
import AutoLinks from "quill-auto-links";
import "quill/dist/quill.snow.css";
import "element-ui/lib/theme-chalk/display.css";
import VueCryptojs from "vue-cryptojs";

import DefaultLayout from '@/template/DefaultTemplate';

Vue.use(VueCryptojs);

Quill.register("modules/imageDrop", ImageDrop);
Quill.register("modules/imageResize", ImageResize);
const quillTable = require("quill-table");
Quill.register(quillTable.TableCell);
Quill.register(quillTable.TableRow);
Quill.register(quillTable.Table);
Quill.register(quillTable.Contain);
Quill.register("modules/table", quillTable.TableModule);
Quill.register("modules/autoLinks", AutoLinks);
Vue.use(VueQuillEditor, /* { default global options } */)

Vue.mixin(Auth);
Vue.mixin(CalPercent);
Vue.use(VueNumeric);

Vue.use(ElementUI, {
  locale
})


Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(
      moment(value)
        .locale("th", momentTH).add(543, 'year')
    ).format('DD/MM/YYYY')
  }
})

Vue.filter('formatDateImport', function (value) {
  if (value) {
    return moment(
      moment(value)
    ).format('DD/MM/YYYY h:mm:ss A')
  }
})


Vue.filter('formatDateTHNumber', function (value) {
  if (value) {
    return moment(value)
      .locale("th", momentTH).add(543, 'year')
      .format('DD/MM/YYYY')
  }
})


Vue.filter('formatDateTH', function (value) {
  if (value) {
    return moment(
      moment(value)
        .locale("th", momentTH).add(543, 'year')
    ).format("ll");
  }
})
Vue.filter('formatDateTHFull', function (value) {
  if (value) {
    return moment(
      moment(value)
        .locale("th", momentTH).add(543, 'year')
    ).format("lll");
  }
})
Vue.filter('formatDateDat', function (value) {
  if (value) {
    return moment(
      moment(value)
        .locale("th", momentTH).add(543, 'year')
    ).format('DD-MM-YYYY')
  }
})
Vue.filter('striphtml', function (value) {
  const strippedString = value.replace(/(<([^>]+)>)/gi, "");
  // console.log(strippedString);
  let rep = new RegExp("&nbsp;", "g")
  let setReplace = strippedString.replaceAll(rep, "")
  // console.log(strippedString);
  return setReplace;
});
Vue.filter('formatDateReport', function (value) {
  if (value) {
    return moment(
      moment(value)
        .locale("th", momentTH)
    ).format("lll น.");
  }
})
Vue.filter('fromNow', function (value) {
  if (value) {
    return moment(moment(value).locale("th", momentTH)).fromNow();
  }
})
Vue.filter("comma", (value) => {
  return numeral(value).format("0,0");
});


Vue.component('default-layout', DefaultLayout);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: function (h) {
    return h(App)
  }
}).$mount('#app')