import { HTTP, HTTPUPLOAD } from "@/service/axios";

export default {
    namespaced: true,
    state: {
        insurance: null,
        projects: [],
        loans: []
    },
    mutations: {
        SET_INSURANCE(state, data) {
            state.insurance = data;
        }
    },
    actions: {
        fetchInsurance({ commit }, payload) {
            return new Promise((resolve, reject) => {
                HTTP.post(payload.url, payload.obj)
                    .then(res => {
                        if (res.data.success) commit("SET_INSURANCE", res.data.obj);
                        resolve(res.data);
                        return;
                    }).catch(err => {
                        console.log('err ', err.responseJSON);
                        reject(err);
                    });
            });
        }
    },
    getters: {

    }
}