export default {
  readinessLevel: [
    {
      value: 1,
      label: "ระดับ 1 - ระดับ 1.9",
    },
    {
      value: 2,
      label: "ระดับ 2 - ระดับ 2.9",
    },
    {
      value: 3,
      label: "ระดับ 3 - ระดับ 3.9",
    },
    {
      value: 4,
      label: "ระดับ 4",
    },
  ],
  gradeByCredit: [
    {
      value: 1,
      label: "Lead Excellent",
    },
    {
      value: 2,
      label: "Lead Good",
    },
    {
      value: 3,
      label: "Lead General",
    },
    {
      value: 4,
      label: "Potential",
    },
    {
      value: 5,
      label: "Non-Prospect",
    },
  ],
  assessReadiness: [
    {
      value: 1,
      label: "ด้านกลยุทธ์การบริหารธุรกิจ",
    },
    {
      value: 2,
      label: "ด้านการผลิตและการกระจายสินค้า",
    },
    {
      value: 3,
      label: "ด้านผลิตภัณฑ์ และการพัฒนาผลิตภัณฑ์",
    },
    {
      value: 4,
      label: "ด้านการตลาด",
    },
    {
      value: 5,
      label: "ด้านแผนการเงินและการจัดการความเสี่ยงในธุรกิจ",
    },
  ],
  readinessFactors: [
    {
      value: 1,
      label: "แรงกระตุ้นจากผู้นำ (Motivation)",
    },
    {
      value: 2,
      label: "กระบวนการบริหารองค์กร (Business Process)",
    },
    {
      value: 3,
      label: "แผนกลยุทธ์ (Strategy)",
    },
    {
      value: 4,
      label: "แผนปฏิบัติการ (Operating Plan)",
    },
    {
      value: 5,
      label: "พันธมิตรสนับสนุน (Strategic Partner)",
    },
    {
      value: 6,
      label: "กำลังการผลิต (Capacity)",
    },
    {
      value: 7,
      label: "การบริหารวัตถุดิบ (Sourcing)",
    },
    {
      value: 8,
      label: "การจัดการสินค้าคงคลัง (Inventory)",
    },
    {
      value: 9,
      label: "การบริหารการกระจายสินค้า (Logistics)",
    },
    {
      value: 10,
      label: "ความโดดเด่นของสินค้า (Product Differentiation)",
    },
    {
      value: 11,
      label: "ความยากในการลอกเลียนแบบสินค้า (Barriers to Copy)",
    },
    {
      value: 12,
      label: "การปรับเปลี่ยนสินค้า (Customization)",
    },
    {
      value: 13,
      label: "มาตรฐานสินค้า (Standards)",
    },
    {
      value: 14,
      label: "การพัฒนาผลิตภัณฑ์ (Product Development)",
    },
    {
      value: 15,
      label: "การสำรวจตลาด (Country & Market Exploration)",
    },
    {
      value: 16,
      label: "การสำรวจกลุ่มลูกค้าเป้าหมาย (Prospect Exploration)",
    },
    {
      value: 17,
      label: "การสำรวจช่องทางตลาด (Channel Exploration)",
    },
    {
      value: 18,
      label: "การแบ่งกลุ่มลูกค้า (Segmentation)",
    },
    {
      value: 19,
      label: "ตำแหน่งการตลาด (Positioning)",
    },
    {
      value: 20,
      label: "แบรนด์ (Branding)",
    },
    {
      value: 21,
      label: "กลยุทธ์ด้านราคา (Pricing)",
    },
    {
      value: 22,
      label: "โครงสร้างต้นทุน (Cost Structure)",
    },
    {
      value: 23,
      label: "การวางแผนการเงิน (Financial Planning)",
    },
    {
      value: 24,
      label: "การบริหารความเสี่ยงธุรกิจ (Risk Mitigation)",
    },
  ],
  // readinessFactors
  
}